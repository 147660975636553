import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {catchError, map, Observable, of} from 'rxjs';
import {AuthService} from "../../services/auth/auth.service";
import {ToastService} from "../../services/misc/toast.service";
import {TOAST_MESSAGES} from "../../constants/constants";
import {AccountService} from "../../services/auth/account.service";
import {AuthDataService} from "../service/auth-data.service";
import {environment} from "../../../environments/environment";
import {createVisitorUrlWithValidation} from "../../utils/common-functions";
import {AccountNameService} from "../../services/account-name/account-name.service";
import {GettingStartedService} from "../../services/misc/getting-started.service";
import {IntercomService} from "../../services/misc/intercom.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  isResetKeyValid: boolean = false
  constructor(
    public router: Router,
    public auth: AuthService,
    public toastService: ToastService,
    public accountService: AccountService,
    public authDataService: AuthDataService,
    public accountNameService: AccountNameService,
    public authService: AuthService,
    public gettingStartedService: GettingStartedService,
    public intercom: IntercomService,
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path = state.url
    const checkForMinoanExperience = environment.production && route.queryParams['redirect'] ? (route.queryParams['redirect'].includes('minoan') || route.queryParams['redirect'].includes('minoanexperience')) : true
    if(!this.auth.isAuthenticated){
      // CASE: Not logged in and trying to access the brands listing page (Old and New)
      if (path.includes('/brands') || path.includes('/shop-and-browse')) {
        if (route.queryParams['redirect'] && checkForMinoanExperience) {
          const redirectUrl = route.queryParams['redirect']
          const link = createVisitorUrlWithValidation(redirectUrl, '')
          sessionStorage.setItem('redirectUrl', link.toString())
        }

        // Get slug from URL for opening brand details modal
        let slug: any = path.split('/')
        slug = slug[slug.length - 1];
        const hasSlug = slug !== 'brands';

        if (route.queryParams['companyId']) {
          const companyId = route.queryParams['companyId'] ? `?companyId=${route.queryParams['companyId']}` : ''
          return this.router.parseUrl(`/auth/login${companyId}`);
        } else if (route.queryParams['product_name']) {
          sessionStorage.setItem('redirectUrl', state.url);
          return this.router.parseUrl('/auth/login')
        } else if (hasSlug) {
          const redirectUrl = `/brands/${slug}`
          sessionStorage.setItem('redirectUrl', redirectUrl);
          return this.router.parseUrl(`/auth/login`)
        } else {
          const key = route.queryParams['key'] ? `?key=${route.queryParams['key']}` : ''
          return this.router.parseUrl(`/auth/login${key}`);
        }
      }

      // CASE: Not logged in and trying to access the shop page
      else if (path.includes('/shop')) {
        const slugFragments = path.split('/')
        const brandsSlug = slugFragments.pop();
        const hasSlug = brandsSlug !== 'shop';
        if (route.queryParams['product_name']) {
          sessionStorage.setItem('redirectUrl', state.url);
          return this.router.parseUrl('/auth/login')
        } else if (route.queryParams['curation'] || route.queryParams['product']) {
          sessionStorage.setItem('redirectUrl', state.url);
          return this.router.parseUrl(`/auth/login`)
        } else if (route.queryParams['companyId']) {
          const companyId = route.queryParams['companyId'] ? `?companyId=${route.queryParams['companyId']}` : ''
          return this.router.parseUrl(`/auth/login${companyId}`);
        } else if (hasSlug){
          const redirectUrl = `/shop/${brandsSlug}`;
          sessionStorage.setItem('redirectUrl', redirectUrl);
          return this.router.parseUrl(`/auth/login`);
        } else {
          const key = route.queryParams['key'] ? `?key=${route.queryParams['key']}` : ''
          return this.router.parseUrl(`/auth/login${key}`);
        }
      }

      else if(path.includes('/auth/reset-password') || path.includes('/auth/create-password')) {
        const queryParams = route.queryParams
        if(Object.keys(queryParams).length < 1 || !queryParams['key']){
          this.toastService.showErrorToast(TOAST_MESSAGES.NO_RESET_KEY);
          return this.router.parseUrl('/auth/login');
        } else {
          const requestBody = {
            key: queryParams['key']
          }
          return this.accountService.validateResetKey(requestBody)
            .pipe(map((response: any) => {
              if (response.isKeyValid == 'valid') {
                // console.log(response)
                this.accountNameService.setAccountDetails(response.companyName,response.accountType)
                return true;
              } else if (response.isKeyValid == 'invalid') {
                this.toastService.showErrorToast(TOAST_MESSAGES.RESET_KEY_INVALID);
                return this.router.parseUrl('/auth/login')
              } else {
                this.isResetKeyValid = true
                return this.router.parseUrl('/auth/expired-link');
              }
          }), catchError(() => {
              this.toastService.showErrorToast(TOAST_MESSAGES.RESET_KEY_INVALID);
              return of(this.router.parseUrl('/auth/login'))
            }));
        }
      }
      else if(path.includes('/auth/setup-account')) {
        const queryParams = route.queryParams
        if(Object.keys(queryParams).length < 1 || !queryParams['key']){
          this.toastService.showErrorToast(TOAST_MESSAGES.NO_SETUP_KEY);
          return this.router.parseUrl('/auth/login');
        } else {
          const requestBody = {
            inviteKey: queryParams['key']
          }
          return this.accountService.validateSetupKeyNewUser(requestBody)
            .pipe(map((response: any) => {
              this.authDataService.accountDetailValue = {
                firstName: response.accountDetails.firstName,
                lastName: response.accountDetails.lastName,
                contactNumber: response.accountDetails.contactNumber,
                email: response.accountDetails.email
              }
              return true;
            }), catchError((err) => {
              if(err == 'The account setup link is no longer valid.'){
                // Invite revoked
                this.toastService.showErrorToast(TOAST_MESSAGES.ACCOUNT_SETUP_INVITE_REVOKED);
              } else if(err == 'No account found with this id! Is the account deleted?'){
                this.toastService.showErrorToast(TOAST_MESSAGES.ACCOUNT_NOT_FOUND);
              } else {
                this.toastService.showErrorToast(TOAST_MESSAGES.SETUP_KEY_INVALID);
              }
              return of(this.router.parseUrl('/auth/login'))
            }));
        }
      }
      else if(path.includes('/auth/login')){
        return true;
      }
      else if(path.includes('/quotes') || path.includes('/quotes/detail') || path.includes('/orders/detail') || path.includes('/onboarding/chromedownload') || path.includes('/onboarding/auto-login')){
        // Store the attempted URL with query parameters in session storage
        sessionStorage.setItem('redirectUrl', state.url);

        // Redirect to the login page
        return this.router.createUrlTree(['/auth/login']);
      }
      else if(path.includes('/auth/forgot-password')){
        return true;
      }
      else if (path.includes('/auth/expired-link')) {
        if (this.isResetKeyValid) {
          this.isResetKeyValid = false
          return true;
        } else {
          return this.router.parseUrl('/auth/login')
        }
      }
      else if(path.includes('/shop-products')) {
        sessionStorage.setItem('redirectUrl', state.url);
        return this.router.parseUrl('/auth/login')
      }
      else if(path.includes('/showroom/setup/share') && route.queryParams['propertyId']){
        const redirectUrl = '/showroom/setup/share';
        sessionStorage.setItem('redirectUrl', redirectUrl.toString());
        sessionStorage.setItem('propertyId', route.queryParams['propertyId']);
        return this.router.parseUrl('/auth/login')
      }
      else {
        return this.router.parseUrl('/auth/login')
      }
    } else {
      if(path.includes('auth')){
        if((path.includes('auth/setup-account') || path.includes('auth/create-password')) && route.queryParams['key']){
          this.toastService.showErrorToast(TOAST_MESSAGES.INVALID_ACCOUNT)
        }
        return this.router.parseUrl('/');
      } else {
        if(route.queryParams['redirect'] && checkForMinoanExperience) {
          const redirectUrl = route.queryParams['redirect']
          const link = createVisitorUrlWithValidation(redirectUrl, this.auth.user.getValue()?.token!)
          window.open(link, '_self');
        }
        if(path.includes('/onboarding/welcome') && !!route.queryParams['showOnboarding']) {
          this.gettingStartedService.allowTemporaryOnboarding()
          this.intercom.hideIntercomWidget();
          return this.router.parseUrl('/onboarding/welcome')
        }
        return true;
      }
    }
  }

}
