import packageJson from '../../package.json';
const version = packageJson.version;

export const environment = {
  production: true,
  apiUrl: 'https://qa-b2bapi.minoanexperience.com',
  shoppableSiteBase: 'visitor-qa.minoanexperience.com',
  amplitude_api_key: '00d13deae897e40c094f7e14aff48a66',
  amplitude_api_url: 'https://nonprod-amplitude.minoan.com/analytics',
  GET_BALANCE_URL: 'https://checkout-v2.sandbox.getbalance.com/sdk-latest.js',
  flagSmithApiKey: 'miBGH4gfRPXR7zj3dgVru8',
  intercom: true,
  wSBrands: [32, 37, 84, 93, 492, 265, 91],
  encryptionKey : 'GZH2K4M5N6',
  dataDogApplicationId:'282a3108-f4dc-4d4e-a556-b3a158b1cd4e',
  dataDogClientToken:'pubd1a33588cf4c8080651a6b13f840371e',
  env: 'qa',
  klaviyoPublicKey: 'XeFT7f',
  intercomAppKey: 'p1lhz1yv',
  showroomBaseURL: 'https://dev-web.minoanexperience.com',
  showroomPreviewURL: 'https://qa-web.minoanexperience.com/shop',
  amplitudeSessionReplay:true, // do not put this space back
  oneSchemaClientId: 'e38eb2b5-95a2-4669-9efa-563e8ae444db',
  DATADOG_API_KEY: '1234',
  DATADOG_SITE: 'us5.datadoghq.com',
  DATADOG_API_HOST: 'api.us5.datadoghq.com',
  applicationVersion: version,
  CLOUDWATCH_APPLICATION_ID: '40f6c26b-b0a4-4a81-a20f-2c31e37e0979',
  CLOUDWATCH_IDENTITY_POOL_ID: 'us-east-1:5ab61985-3e05-4432-8c6f-6919ee0d1a78'
};
